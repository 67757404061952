import React from "react";

// 3rd-party
import moment from "moment";
import {
  FaEdit,
  FaRegClock,
  FaPhone,
  FaEnvelope,
  FaMap,
  FaBirthdayCake,
  FaUserAlt,
  FaFacebook,
  FaYoutube,
  FaInstagram,
  FaRegImage,
  FaVideo,
  FaMicrophone,
  FaLocationArrow,
  FaMapMarkerAlt,
  FaLandmark,
  FaTags,
  FaStar,
  FaPen,
} from "react-icons/fa";

// Redux
import {useSelector} from "react-redux";
import {selectActiveAsset} from "../../../../../../redux/asset/asset.selectors";

// Reeler components
import AssetTextField from "./AssetTextField";
import FieldValue from "./FieldValue";
import AssetViewIGPost from "./AssetViewIGPost";
import * as S from "../../styles";
import {CAMPAIGN_TYPE, MEDIA_TYPE} from "../../../../../../constants";
import AssetSourceName from "./AssetSourceName";
import AssetShare from "./AssetShare";
import AssetUsername from "./AssetUsername";
import EditedCaption from "./EditedCaption";
import {capitalize} from "lodash";
import AssetCreatorHashtags from "./AssetCreatorHashtags";
import {
  BsBoxFill,
  BsCardText,
  BsFileEarmarkRichtext,
  BsStars,
} from "react-icons/bs";
import ReelerTooltip from "../../../../../commons/ReelerTooltip/ReelerTooltip";
import SingleRowIcon from "./SingleRowIcon";
import AssetMap from "./AssetMap/AssetMap";
import AssetCreator from "./AssetCreator";
import AuthBasedComponent from "../../../../../authentication/AuthBasedComponent";
import {ACCOUNT_PLANS, MAIN_ROUTES} from "../../../../../../constants/routes";
import AssetRate from "./AssetRate";
import AssetNotes from "./AssetNotes";
import AssetTagsRedux from "./AssetTagsRedux";
import AssetRights from "./AssetRights";
import AssetProducts from "./AssetProducts/AssetProducts";
import AssetAddCaption from "./AssetAddCaption";
import AssetObjectLabels from "./AssetObjectLabels";
import formatAddress from "../../../../../../utils/FormattedAddress";

const aiParameters = [
  "resolution",
  "sharpness",
  "colors",
  "composition",
  "creativity",
];
const ScoreParameter = ({parameter, score}) => (
  <div className="d-flex flex-row align-items-center me-2">
    <span className="me-1 capitalize">{parameter}:</span>
    <span>{score}</span>
  </div>
);

export default function AssetDetails() {
  const asset = useSelector(selectActiveAsset);

  return (
    <S.ModalTabContainer>
      <div
        style={{
          width: "100%",
          padding: "0.5rem",
        }}
      >
        {asset.createdAt && (
          <AssetTextField
            icon={
              <SingleRowIcon tooltip="Date of collection">
                <FaRegClock className="icon-color" />
              </SingleRowIcon>
            }
            text={moment(asset.createdAt.toDate()).format("YYYY-MM-DD")}
          />
        )}
        {false && asset?.media_type !== MEDIA_TYPE.TEXT && (
          <AssetTextField
            icon={
              <SingleRowIcon tooltip="Metadata">
                {asset.media_type === MEDIA_TYPE.IMAGE ? (
                  <FaRegImage className="icon-color" />
                ) : asset.media_type === MEDIA_TYPE.VIDEO ? (
                  <FaVideo className="icon-color" />
                ) : asset.media_type === MEDIA_TYPE.AUDIO ? (
                  <FaMicrophone className="icon-color" />
                ) : null}
              </SingleRowIcon>
            }
            text={
              asset.media_type !== MEDIA_TYPE.AUDIO
                ? asset?.metadata
                  ? `${asset?.metadata?.type} (${capitalize(
                      asset?.metadata?.orientation
                    )}, ${asset?.metadata?.dimensions?.width}x${
                      asset?.metadata?.dimensions?.height
                    }, ${asset?.metadata?.aspect_ratio?.aspectRatio})`
                  : asset?.meta_data
                  ? `${asset?.meta_data?.type} (${asset?.meta_data?.dimensions?.width}x${asset?.meta_data?.dimensions?.height}, ${asset?.meta_data?.aspect_ratio?.aspectRatio})`
                  : asset.type
                : asset.type
            }
          />
        )}

        <AssetSourceName />
        {asset?.ai_score && (
          <AssetTextField
            icon={
              <SingleRowIcon tooltip="Reeler AI Content Score (0-10): Helps you identify your most authentic and engaging content. Scoring prioritizes real-life photography, but also weighs in technical factors (resolution, sharpness) and artistic aspects (color, composition, and creativity).">
                <BsStars />
              </SingleRowIcon>
            }
            text={
              <div className="d-flex flex-row align-items-center">
                <strong className="me-3">{asset?.ai_score?.total_score}</strong>
                <div className="d-flex flex-row align-items-center">
                  {aiParameters.map((parameter, index) => (
                    <ScoreParameter
                      key={index}
                      parameter={parameter}
                      score={asset?.ai_score?.parameters[parameter]}
                    />
                  ))}
                </div>
              </div>
            }
          />
        )}

        <AssetTextField
          icon={
            <SingleRowIcon tooltip="AI landmark detection: If a recognizable landmark or location is detected, its details and a map link are displayed.">
              <FaLandmark />
            </SingleRowIcon>
          }
          text={
            asset?.landmark ? (
              <div className="d-flex flex-row align-items-center">
                <span>
                  {asset?.landmark?.name}
                  {asset?.landmark?.name &&
                  (asset?.landmark?.address_components?.locality ||
                    asset?.landmark?.address_components?.country)
                    ? ", "
                    : null}
                  {formatAddress(asset?.landmark?.address_components)}
                </span>
                {asset.landmark?.latlng &&
                asset.landmark?.latlng?.latitude &&
                asset.landmark?.latlng?.longitude ? (
                  <div className="mx-2">
                    <AssetMap
                      location={{
                        description: asset?.landmark?.name,
                        lat: asset.landmark?.latlng?.latitude,
                        lng: asset.landmark?.latlng?.longitude,
                      }}
                    />
                  </div>
                ) : null}
              </div>
            ) : null
          }
        />

        <AssetTextField
          icon={
            <SingleRowIcon tooltip="Camera location: The location where the photo was captured, based on the metadata from the camera.">
              <FaMapMarkerAlt />
            </SingleRowIcon>
          }
          text={
            asset?.location ? (
              <div className="d-flex flex-row align-items-center">
                <span>{asset.location?.formatted_address}</span>
                {asset.location?.geometry && (
                  <div className="mx-2">
                    <AssetMap
                      location={{
                        description: asset.location?.formatted_address,
                        lat: asset?.location?.geometry.lat,
                        lng: asset?.location?.geometry.lng,
                      }}
                    />
                  </div>
                )}
              </div>
            ) : null
          }
        />

        <AssetTextField
          icon={
            <SingleRowIcon tooltip="Rate">
              <FaStar className="mb-2 icon-color" />
            </SingleRowIcon>
          }
          text={<AssetRate />}
        />

        <AssetTextField
          icon={
            <ReelerTooltip text="Notes">
              <FaPen className="icon-color" />
            </ReelerTooltip>
          }
          text={<AssetNotes />}
        />

        <div
          className="my-2"
          style={{
            backgroundColor: "var(--reeler-grey)",
          }}
        >
          <AuthBasedComponent plans={MAIN_ROUTES.creator.plans}>
            <AssetCreator />
          </AuthBasedComponent>
          <AuthBasedComponent
            plans={[
              ACCOUNT_PLANS.basic,
              ACCOUNT_PLANS.pro,
              ACCOUNT_PLANS.premium,
            ]}
          >
            <AssetTextField
              icon={
                <ReelerTooltip text="Rights">
                  <FaPen className="icon-color" />
                </ReelerTooltip>
              }
              text={<AssetRights />}
            />
          </AuthBasedComponent>
        </div>
        <AssetTextField
          icon={
            <ReelerTooltip text="Labels: Add a label by typing it in and hitting Enter or by selecting from the dropdown of existing “account labels”. To save it as a new account label, use the green button instead. Manage account labels under “Settings”.">
              <FaTags className="icon-color" />
            </ReelerTooltip>
          }
          text={<AssetTagsRedux />}
        />
        <AssetTextField
          icon={
            <ReelerTooltip text="AI object tags: Automatically generated tags describing the objects and concepts detected in the image.">
              <BsStars className="icon-color" />
            </ReelerTooltip>
          }
          text={<AssetObjectLabels />}
        />
        <AuthBasedComponent
          plans={[
            ACCOUNT_PLANS.basic,
            ACCOUNT_PLANS.pro,
            ACCOUNT_PLANS.premium,
          ]}
        >
          <AssetTextField
            icon={
              <ReelerTooltip text="Products">
                <BsBoxFill className="icon-color" />
              </ReelerTooltip>
            }
            text={<AssetProducts />}
          />
        </AuthBasedComponent>
        <AuthBasedComponent
          plans={[
            ACCOUNT_PLANS.basic,
            ACCOUNT_PLANS.pro,
            ACCOUNT_PLANS.premium,
          ]}
        >
          <AssetTextField
            icon={
              <ReelerTooltip text="Edited caption">
                <BsFileEarmarkRichtext className="icon-color" />
              </ReelerTooltip>
            }
            text={<AssetAddCaption />}
          />
        </AuthBasedComponent>
        {asset?.thirdPartyApprovals?.third_party_email ? (
          <S.FieldContainer>
            <S.FieldTitle>Approval email</S.FieldTitle>
            <FieldValue value={asset?.thirdPartyApprovals?.third_party_email} />
          </S.FieldContainer>
        ) : null}
      </div>
    </S.ModalTabContainer>
  );
}
